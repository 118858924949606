import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import styles from "styles/layout.module.css";

export default function IsLoggedOutWrapper({ children }) {
  // ensure that when logged in, certain pages are inaccessible [/login, /signup/*]
  const router = useRouter();
  const { data, status } = useSession();

  useEffect(() => {
    let path = router.asPath;

    if (
      (path.startsWith("/login") ||
        path.startsWith("/signup") ||
        path === "/") &&
      status === "authenticated" &&
      data?.user
    ) {
      if (path.startsWith("/login") && router.query.from && (router.query.from as string).startsWith('/')) {
        router.push(router.query.from as string);
      } else {
        router.push("/home");
      }
    }
  }, [router.asPath, data]);

  if (status !== "unauthenticated") return null;

  return <div className={styles.page}>{children}</div>;
}
