import { Formik } from "formik";
import React, { useContext, useState } from "react";
import Button, { SlimButton } from "../fields/button";
import LabelInput from "components/fields/input";

import styles from "styles/signup/new-account.module.css";
import { StoreContext } from "@/context";
import { eachDayOfInterval, isMatch, isValid, parse, subYears } from "date-fns";
import { ref, object, string } from "yup";
import { DATE_FORMAT, MAX_AGE, MIN_AGE } from "@/utils/constants";
import ErrorTag from "../ErrorTag";

import { debounce } from 'lodash';
import { getUserByReferralCode } from '@/services/users';

import Link from 'next/link';

interface Invalid {
    dob?: string;
    name?: string;
    teamName?: string;
    email?: string;
}
/**
 * New account Form
 * @param {Object} props
 * @returns
 */
export default function NewAccount({ onSubmit }) {
    const [isValidating, setIsValidating] = useState(false);
    const [isReferralCodeValid, setIsReferralCodeValid] = useState(true);

    const submitForm = async (values) => {
        return onSubmit && onSubmit(values);
    };

    const debouncedReferralCodeValidation = debounce((code, setFieldError) => {
        if (code === '') {
            setIsReferralCodeValid(true);
            return true;
        }
        // Send request only if within the pattern
        if (code.length === referralCodeLength || code.match(referralCodePattern)) {
            setIsValidating(true);
            try {
                getUserByReferralCode(code)
                    .then(res => {
                        if (!res || res.status !== 200) {
                            setFieldError('referral', 'Invalid Referral Code');
                        } else {
                            setIsReferralCodeValid(true);
                        }
                    }).finally(() => {
                        setIsValidating(false);
                    });
            } catch (error) {
                setFieldError('referral', 'Invalid Referral Code');
                setIsValidating(false);
                return false
            }
        }

    },
        1000,
        { trailing: true }
    );

    return (
        <div className={styles.form}>
            <Formik
                initialValues={defaultValues}
                validationSchema={validation}
                validateOnChange={true}
                onSubmit={onSubmit}
            >
                {({
                    values: {
                        profile_name,
                        email,
                        team_name,
                        password,
                        dob,
                        confirm,
                        referral
                    },
                    errors: {
                        profile_name: profileNameError,
                        email: emailError,
                        team_name: teamNameError,
                        password: passwordError,
                        dob: dobError,
                        confirm: confirmError,
                        referral: referralError
                    },
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isValid,
                    isSubmitting,
                    submitForm,
                    handleChange,
                    setFieldError
                }) => {

                    const handleReferralChange = (e) => {
                        handleChange(e);
                        if (typeof e !== 'undefined') {
                            setIsReferralCodeValid(false);
                            debouncedReferralCodeValidation(e, setFieldError);
                        } else {
                            setIsReferralCodeValid(true);
                        }
                    };

                    return (
                        <div className={styles.formFieldContainer}>
                            <div>
                                <LabelInput
                                    label="Email"
                                    placeholder="Email"
                                    value={email}
                                    onBlur={() => setFieldTouched("email")}
                                    onChange={(e) => {
                                        setFieldTouched("email");
                                        setFieldValue("email", e);
                                    }}
                                    inputOptions={{ name: 'email ' }}
                                />
                                {emailError && touched.email && (
                                    <ErrorTag message={emailError} />
                                )}
                            </div>
                            <div>
                                <LabelInput
                                    label="Username"
                                    placeholder="Username"
                                    value={profile_name}
                                    onBlur={() => setFieldTouched("profile_name")}
                                    onChange={(e) => {
                                        setFieldTouched("profile_name");
                                        setFieldValue("profile_name", e);
                                    }}
                                    inputOptions={{ name: 'profileName ' }}
                                />
                                {profileNameError && touched.profile_name && (
                                    <ErrorTag message={profileNameError} />
                                )}
                            </div>
                            <div>
                                <LabelInput
                                    label="Team Name"
                                    placeholder="Team Name"
                                    value={team_name}
                                    onBlur={() => setFieldTouched("team_name")}
                                    onChange={(e) => {
                                        setFieldTouched("team_name");
                                        setFieldValue("team_name", e);
                                    }}
                                    inputOptions={{ name: 'teamName ' }}
                                />
                                {teamNameError && touched.team_name && (
                                    <ErrorTag message={teamNameError} />
                                )}
                            </div>
                            <div>
                                <>
                                    <LabelInput
                                        label="Date of Birth"
                                        placeholder={DATE_FORMAT}
                                        value={dob}
                                        onBlur={() => setFieldTouched("dob")}
                                        onChange={(val, e) => {
                                            const { inputType, data } = e?.nativeEvent ?? {}
                                            if (inputType === 'deleteContentBackward') {
                                                setFieldValue("dob", val);
                                                return
                                            }
                                            if (!data?.match(/^[0-9]+$/g)) return
                                            let newVal = val
                                            if (newVal.length === 2 && newVal[newVal.length - 1] !== '/') {
                                                newVal = `${newVal}/`
                                            }
                                            if (newVal.length === 5 && newVal[newVal.length - 1] !== '/') {
                                                newVal = `${newVal}/`
                                            }
                                            setFieldValue("dob", newVal);
                                        }}
                                        inputOptions={{ name: 'dob ' }}
                                    />
                                    {dobError && touched.dob && (
                                        <ErrorTag message={dobError} />
                                    )}
                                </>
                            </div>
                            <div>
                                <LabelInput
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onBlur={() => setFieldTouched("password")}
                                    onChange={(e) => {
                                        setFieldTouched("psssword");
                                        setFieldValue("password", e);
                                    }}
                                    inputOptions={{ name: 'password ' }}
                                />
                                {passwordError && touched.password && (
                                    <ErrorTag message={passwordError} />
                                )}
                            </div>
                            <div className={styles.pwInstruct}>
                                Must contain 1 number, 1 uppercase, 1 lowercase and a special character (!@#$%^&*)
                            </div>
                            <div>
                                <LabelInput
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    type="password"
                                    value={confirm}
                                    onBlur={() => setFieldTouched("confirm")}
                                    onChange={(e) => {
                                        setFieldTouched("confirm");
                                        setFieldValue("confirm", e);
                                    }}
                                    inputOptions={{ name: 'confirmPassword ' }}
                                />
                                {confirmError && touched.confirm && (
                                    <ErrorTag message={confirmError} />
                                )}
                            </div>

                            <div>
                                <LabelInput
                                    label="Referral Code (optional)"
                                    placeholder="Referral Code"
                                    value={referral}
                                    onBlur={(e) => setFieldTouched("referral")}
                                    onChange={(e) => {
                                        setFieldTouched("referral");
                                        setFieldValue("referral", e);
                                        handleReferralChange(e)
                                    }}
                                    inputOptions={{ name: 'referral ' }}
                                />
                                {referralError && touched.referral && (
                                    <ErrorTag message={referralError} />
                                )}
                            </div>
                            {/* <div className={styles.tAndCCheckbox}>
                            <input className={styles.checkbox} type="checkbox" id='agreeToTermsAndConditions' />
                            <label htmlFor="agreeToTermsAndConditions">I agree to the Terms & Conditions and Privacy Policy</label>
                        </div> */}
                            <div>
                                <p className={styles.byClickingSignUp}>
                                    By clicking "Sign up" you agree to the CryptoSports
                                    <br />
                                    <span>
                                        <a href="/terms" target="_blank" rel="noreferrer">
                                            <a>Terms of Service</a>
                                        </a>
                                    </span>{" "}
                                    and{" "}
                                    <span>
                                        <a href="/privacy" target="_blank" rel="noreferrer">
                                            <a>Privacy Policy</a>
                                        </a>
                                    </span>
                                </p>
                                <SlimButton
                                    disabled={isValid === false || isSubmitting || isValidating || !isReferralCodeValid}
                                    text={isSubmitting ? "Submitting..." : "Sign up"}
                                    onClick={submitForm}
                                    className={styles.formButton}
                                />
                            </div>
                        </div>
                    )

                }}
            </Formik>
        </div>
    );
}

const referralCodeLength = 6;
const referralCodePattern = new RegExp(`^[a-z0-9]{${referralCodeLength}}$`, 'i');

const validation = object().shape({
    email: string().required("Email required").email("Email not valid"),
    profile_name: string().required("Username required"),
    team_name: string().required("Team Name required"),
    dob: string()
        .nullable()
        .required("Date of birth required")
        .test("test-name", `DOB must be in format ${DATE_FORMAT}`, (e) =>
            isMatch(e, DATE_FORMAT)
        )
        .test(
            "test-old",
            `Must be 18 years or older`,
            (e) =>
                parse(e, DATE_FORMAT, new Date()) <=
                subYears(new Date(), MIN_AGE)
        )
        .test(
            "real-date",
            `Date invalid`,
            (e) =>
                parse(e, DATE_FORMAT, new Date()) >=
                subYears(new Date(), MAX_AGE)
        ),
    password: string()
        .required("Password required")
        .min(8, "Must be 8 or more characters")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must contain 1 number, 1 uppercase, 1 lowercase and a special character (!@#$%^&*)"
        ),
    confirm: string()
        .required("Reenter password")
        .oneOf([ref("password")], "Passwords must match"),
    referral: string()
        .min(referralCodeLength, "Invalid Referral Code")
        .max(referralCodeLength, "Invalid Referral Code")
        .matches(
            referralCodePattern,
            "Invalid Referral Code"
        )
});

const defaultValues = {
    email: "",
    profile_name: "",
    team_name: "",
    dob: null,
    password: "",
    confirm: "",
    referral: ""
};
