import { CURRENCY } from '@/utils/constants'
import React from 'react'
import styles from './Currency.module.css'
import clsx from 'clsx'

const colors = {
  primary: '#007AFF',
  white: '#ffffff',
  black: '#000000'
}

const Currency = ({
  currency,
  color = 'primary',
  size = 24,
  className
}: {
  currency?: string,
  color?: string,
  size?: number,
  className?: string
}) => {
  const isFiat = CURRENCY === 'FIAT'
  const isToken = CURRENCY === 'TOKEN'

  if (isFiat) return <span>{currency}</span>
  if (isToken) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size.toString()} height={size.toString()} viewBox={`0 0 ${size} ${size}`} fill="none" className={clsx(styles.svg, className)}>
        <circle cx={(size/2).toString()} cy={(size/2).toString()} r={size*0.375} stroke={colors[color]} strokeWidth={(size/4).toString()}/>
      </svg>
    )
  }
  return null
}

export default Currency